import React from 'react';
import PropTypes from 'prop-types';

import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';
import Nav from '../components/navbar/Navbar';

const Navbar = ({ sticky }) => {
  const data = useStaticQuery(GET_NAVBAR);
  const { frontmatter } = data.markdownRemark;
  return (
    <Nav
      sticky={sticky}
      logo={frontmatter.logo}
      links={frontmatter.links}
      contact={frontmatter.contact}
      localization={frontmatter.localization}
    />
  );
};
Navbar.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};
export default Navbar;
export const GET_NAVBAR = graphql`
  query getNavbarQuery {
    markdownRemark(frontmatter: { templateKey: { eq: "navbar" } }) {
      frontmatter {
        logo {
          publicURL
        }
        templateKey
        links {
          title_fr
          title_en
          url
        }
        contact {
          title_fr
          title_en
          url
        }
        localization {
          title
          externalLinks {
            title
            url
          }
          internalLink {
            title
          }
        }
      }
    }
  }
`;
