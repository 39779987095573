import React from 'react';
import { Helmet } from 'react-helmet';
import ScrollButton from './Scroll Button/scrollUp';
import useSiteMetadata from './SiteMetadata';
import loadable from '@loadable/component';
import '../style/stylesheet.scss';
import Navbar from '../templates/navbar';
import BannerTemplate from '../templates/banner';

const FooterComponent = loadable(() => import('../templates/footer'));
const HeaderComponent = loadable(() => import('../templates/header'));

export const LangContext = React.createContext('en');
export const LanguageConsumer = LangContext.Consumer;

const Layout = ({ children }) => {
  const { title, description } = useSiteMetadata();
  const [lang, setLang] = React.useState(
    (typeof window !== 'undefined' && localStorage.getItem('language')) || 'en',
  );

  const handleChange = event => {
    setLang(event.target.value);
    if (typeof window !== 'undefined') {
      localStorage.setItem('language', event.target.value);
    }
  };
  return (
    <LangContext.Provider
      value={{
        language: lang,
        updateLanguage: handleChange,
      }}
    >
      <Helmet>
        <html lang={lang} />
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="icon" type="image/png" href="/img/theodo-favicon.png" />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
      </Helmet>

      <BannerTemplate />
      <HeaderComponent />
      <Navbar sticky={true} />
      <div style={{ maxWidth: '2000px', margin: '0px auto' }}>{children}</div>
      <ScrollButton scrollStepInPx="50" delayInMs="16.66" />
      <FooterComponent />
    </LangContext.Provider>
  );
};

export default Layout;
