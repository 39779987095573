import React, { useState } from 'react';
import styles from './LocalizationButton.module.scss';
import WorldSvg from '../../img/world.inline.svg';
import ChevronUpSvg from '../../img/chevron-up.inline.svg';
import { Popover } from '@material-ui/core';
import cx from 'classnames';
import { Link } from 'gatsby';
import MapLocationIcon from '../../img/map-location.inline.svg';

const LocalizationButton = ({ title, externalLinks, internalLink }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button className={styles.localizationButton} onClick={handleClick}>
        <span className={styles.icon}>
          <WorldSvg />
        </span>

        <span
          className={cx(styles.icon, {
            [styles.localizationButtonIconOpen]: isOpen,
          })}
        >
          <ChevronUpSvg />
        </span>
      </button>

      <Popover
        open={isOpen}
        anchorEl={anchorEl}
        PaperProps={{ className: styles.localizationContent }}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 80,
          horizontal: 100,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.titleContainer}>
          <MapLocationIcon />
          <p className={styles.title}>{title}</p>
        </div>

        {externalLinks.map(({ title, url }) => (
          <Link key={url} to={url || '/'}>
            <button className={cx(styles.button, styles.secondaryButton)}>
              {title}
            </button>
          </Link>
        ))}

        <button
          className={cx(styles.button, styles.primaryButton)}
          onClick={handleClose}
        >
          <ChevronUpSvg className={styles.ourWebsiteIcon} />
          {internalLink.title}
        </button>
      </Popover>
    </div>
  );
};

export default LocalizationButton;
