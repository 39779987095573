/* eslint-disable */

import React, { useContext, useState } from 'react';
import { Link } from 'gatsby';
import MobileMenu from '../../img/mobile-menu.inline.svg';
import cx from 'classnames';
import './navbar.scss';
import { LangContext } from '../Layout';
import { Close } from '@material-ui/icons';
import LocalizationButton from '../localizationButton/LocalizationButton';

const Navbar = ({ logo, links, contact, localization, sticky }) => {
  const [open, setOpen] = useState(false);
  const { language } = useContext(LangContext);
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const openMenu = () => {
    setOpen(!open);
  };
  return (
    <>
      <div className={cx('navbar_container', { sticky: sticky })}>
        <Link to="/" className="navbar_container__logo">
          {logo && <img src={logo.publicURL} alt="Theodo" className={'logo'} />}
        </Link>
        <div className="navbar_container__menu" id="myTopnav">
          <i onClick={openMenu} className="navbar_container__menu__mobile">
            <MobileMenu />
          </i>
          <div className="navbar_container__menu__nav">
            {links &&
              links.map((link, i) => {
                if (link['title_' + language] === 'Blog') {
                  return (
                    <a
                      href="https://blog.theodo.ma/"
                      key={i}
                      className="navbar_container__menu__nav__menu-item"
                      target="_blank"
                      rel="noopener"
                    >
                      {link['title_' + language]}
                    </a>
                  );
                } else
                  return (
                    <Link
                      to={`/${link.url}`}
                      key={i}
                      className={cx('navbar_container__menu__nav__menu-item', {
                        navbar_container__nav__active_menu_item:
                          link.title === 'Expertise' &&
                          url.includes('expertise'),
                      })}
                      activeClassName="navbar_container__menu__nav__active_menu_item"
                    >
                      {link['title_' + language]}
                    </Link>
                  );
              })}
          </div>
          {contact && (
            <Link
              to={`/${contact.url}`}
              className="navbar_container__menu__nav__menu-item-with-border"
              activeClassName="navbar_container__menu__nav__active_menu-item-with-border"
            >
              {contact['title_' + language]}
            </Link>
          )}
          <LocalizationButton
            title={localization.title}
            externalLinks={localization.externalLinks}
            internalLink={localization.internalLink}
          />
        </div>
      </div>
      {open && (
        <div className="mobile-menu">
          <div className="mobile-menu__header">
            <Link to="/" className="navbar_container__logo">
              {logo && (
                <img src={logo.publicURL} alt="Theodo" className={'logo'} />
              )}
            </Link>
            <Close
              style={{ color: 'white' }}
              fontSize="large"
              className="mobile-menu__header__icon"
              onClick={openMenu}
            />
          </div>
          {links &&
            links.map((link, i) => {
              if (link['title_' + language] === 'Blog') {
                return (
                  <a
                    href="https://blog.theodo.ma/"
                    key={i}
                    className="mobile-menu__item"
                    target="_blank"
                    rel="noopener"
                  >
                    {link['title_' + language]}
                  </a>
                );
              } else
                return (
                  <Link
                    to={`/${link.url}`}
                    key={i}
                    className={cx('mobile-menu__item', {
                      navbar_container__nav__active_menu_item:
                        link.title === 'Expertise' && url.includes('expertise'),
                    })}
                  >
                    {link['title_' + language]}
                  </Link>
                );
            })}
          {contact && (
            <Link to={`/${contact.url}`} className="mobile-menu__item">
              {contact['title_' + language]}
            </Link>
          )}
          <div className="mobile-menu__footer">
            <div className="mobile-menu__footer__header">
              <img
                className={'logo'}
                src="/img/white-theodo.svg"
                alt={'theodo'}
              />
              <div className="mobile-menu__footer__header__icons">
                <a
                  className={'mobile-menu__footer__header__icons_logo'}
                  href="/"
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <img src={'/img/youtube.svg'} alt={'youtube'} />
                </a>
                <a
                  className={'mobile-menu__footer__header__icons_logo'}
                  href="/"
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <img src={'/img/twitter.svg'} alt={'twitter'} />
                </a>
                <a
                  className={'mobile-menu__footer__header__icons_logo'}
                  href="/"
                  target={'_blank'}
                  rel="noopener noreferrer"
                >
                  <img src={'/img/linkedIn.svg'} alt={'linkedIn'} />
                </a>
              </div>
            </div>
            <div className="mobile-menu__footer__text">
              Copyright 2024 Theodo® All rights reserved
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default Navbar;
